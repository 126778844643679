import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"
import "chartjs-adapter-moment"

export default class extends Controller {
  static targets = [
    "canvas",
    "years",
    "endingBalance",
    "totalContribution",
    "totalInterest",
    "dataTable",
    "dataTableYear",
    "dataTableStartingBalance",
    "dataTableContributions",
    "dataTableInterestEarned",
    "dataTableEndingBalance"
  ]

  static classes = [
    "positiveBalance",
    "negativeBalance"
  ]

  connect() {
    if (this.form) this.form.requestSubmit()
  }

  submitData(event) {
    event.preventDefault()

    const formData = new FormData(event.target);
    for (const [key, value] of formData.entries()) {
      this[key] = parseFloat(value);
    }

    this.dataPoints = this.calculateDataPoints();
    this.renderChart();
    this.renderInfo();
  }

  calculateDataPoints() {
    const dataPoints = [];
    let balance = parseFloat(this.starting_balance);
    let cumulativeContribution = 0;
    let totalInterest = 0;
    const annualContribution = this.monthly_contribution * 12;

    for (let year = 0; year <= this.years; year++) {
      const contributions = year === 0 ? 0 : annualContribution;
      const previousBalance = balance;
      cumulativeContribution += contributions;

      // Calculate interest earned and update balance
      let interestEarned;
      if (this.compounding_frequency === "compounding_frequency_monthly") {
        const monthly_rate = this.interest_rate / 12 / 100;
        for (let month = 0; month < 12; month++) {
          balance = balance * (1 + monthly_rate) + this.monthly_contribution;
        }
      } else {
        const annual_rate = parseFloat(this.interest_rate) / 100;
        balance = balance * (1 + annual_rate) + contributions;
      }

      interestEarned = balance - (previousBalance + contributions);
      totalInterest += interestEarned;

      dataPoints.push({
        year,
        startingBalance: previousBalance,
        contributions: cumulativeContribution, // Cumulative contributions for the year
        interestEarned,
        endingBalance: balance,
        totalContribution: cumulativeContribution,
        totalInterest,
      });
    }

    return dataPoints;
  }

  renderChart() {
    let ctx = this.canvasTarget.getContext("2d");

    let chart = Chart.getChart(ctx)
    if (chart) chart.destroy()

    new Chart(ctx, {
      type: "line",
      data: {
        labels: this.dataPoints.map(point => point.year), // Include Year 0 in labels
        datasets: [
          {
            label: "Earnings",
            data: this.dataPoints.map(point => point.endingBalance),
            borderColor: "rgba(249, 196, 20, 1)",
          },
          {
            label: "Contributions",
            data: this.dataPoints.map(point => point.totalContribution),
            borderColor: "rgba(102, 102, 102, 1)"
          },
        ]
      },
      options: this.lineChartOptions,
      plugins: [this.lineChartVerticalLinePlugin()]
    });
  }

  renderInfo() {
    this.#renderOverview();
    this.#renderBreakdown();
  }

  #renderOverview() {
    const finalDataPoint = this.dataPoints[this.dataPoints.length - 1];
    this.endingBalanceTarget.textContent = this.formatCurrency(finalDataPoint.endingBalance);

    if (finalDataPoint.endingBalance > 0) {
      this.endingBalanceTarget.classList.add(...this.positiveBalanceClasses);
      this.endingBalanceTarget.classList.remove(...this.negativeBalanceClasses);
    } else {
      this.endingBalanceTarget.classList.add(...this.negativeBalanceClasses);
      this.endingBalanceTarget.classList.remove(...this.positiveBalanceClasses);
    }

    const years = this.years === 1 ? "year" : "years";
    this.yearsTarget.textContent = `${this.years} ${years}`;
  }

  #renderBreakdown() {
    const tbody = this.dataTableTarget.querySelector("tbody");
    tbody.innerHTML = ""; // Clear any existing rows

    this.dataPoints.slice(1).forEach(point => { // Exclude Year 0
      const interestSign = point.interestEarned >= 0 ? "+" : "-";
      const interestClasses = point.interestEarned >= 0 ? this.positiveBalanceClasses : this.negativeBalanceClasses;

      const row = document.createElement("tr");
      row.innerHTML = `
        <td class="pb-2.5">${point.year}</td>
        <td class="pb-2.5 hidden md:table-cell">${this.formatCurrency(point.startingBalance)}</td>
        <td class="pb-2.5 hidden md:table-cell">${this.formatCurrency(point.contributions)}</td>
        <td class="pb-2.5 ${interestClasses.join(' ')}">
          ${interestSign}${this.formatCurrency(Math.abs(point.interestEarned))}
        </td>
        <td class="pb-2.5">${this.formatCurrency(point.endingBalance)}</td>
      `;
      tbody.appendChild(row);
    });

    const finalDataPoint = this.dataPoints[this.dataPoints.length - 1];
    this.totalContributionTarget.textContent = this.formatCurrency(finalDataPoint.totalContribution);
    this.totalInterestTarget.textContent = this.formatCurrency(finalDataPoint.totalInterest);
    this.dataTableTarget.classList.remove("hidden");
  }

  lineChartVerticalLinePlugin() {
    return {
      id: "verticalLine",
      defaults: {
        width: 2,
        color: "#d1d5db",
        dash: [5, 5],
      },
      afterInit: (chart, args, opts) => {
        chart.verticalLine = {
          x: 0,
          y: 0,
        }
      },
      afterEvent: (chart, args) => {
        const { inChartArea } = args
        const { type, x, y } = args.event

        chart.verticalLine = { x, y, draw: inChartArea }
        chart.draw()
      },
      afterDraw: (chart, args, opts) => {
        const { ctx } = chart
        const { top, bottom } = chart.chartArea
        if (!chart.verticalLine) return
        const { x, draw } = chart.verticalLine
        if (!draw) return

        ctx.save()

        ctx.beginPath()
        ctx.lineWidth = opts.width
        ctx.strokeStyle = opts.color
        ctx.setLineDash(opts.dash)
        ctx.moveTo(x, bottom)
        ctx.lineTo(x, top)
        ctx.stroke()

        ctx.restore()
      }
    }
  }

  get form() {
    return this.element.querySelector("form")
  }

  get lineChartOptions() {
    return {
      tooltips: {
        displayColors: false,
      },
      maintainAspectRatio: false,
      tension: 0,
      plugins: {
        verticalLine: {
          color: "rgba(250, 204, 21, 0.5)",
        },
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          backgroundColor: "rgba(39, 39, 42, 1)",
          caretSize: 0,
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || "";

              if (label) {
                label += ": ";
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },
      scales: {
        y: {
          display: true,
          ticks: {
            display: true,
            callback: (value, index, values) => {
              return Intl.NumberFormat("en-US", {
                notation: "compact",
                maximumFractionDigits: 0,
                style: "currency",
                currency: "USD",
              }).format(value);
            },
          },
          border: {
            dash: [5, 5],
          },
          grid: {
            color: "rgba(102, 102, 102, 0.1)",
          }
        },
        x: {
          border: {
            dash: [5, 5],
          },
          grid: {
            color: "rgba(102, 102, 102, 0.1)",
          }
        }
      },
      elements: {
        point: {
          pointStyle: false,
        },
      },
      interaction: {
        mode: "index",
        intersect: false
      },
    }
  }

  formatCurrency(value) {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    }).format(value);
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]
  static values = {
    speed: { type: Number, default: 1 },
    direction: { type: String, default: "left" }
  }

  connect() {
    this.position = 0
    this.speed = this.speedValue

    // Bind methods to ensure correct 'this' context
    this.updateWidth = this.updateWidth.bind(this)
    this.animate = this.animate.bind(this)

    this.updateWidth()
    this.startTicker()
    window.addEventListener('resize', this.updateWidth)
  }

  disconnect() {
    this.stopTicker()
    window.removeEventListener('resize', this.updateWidth)
  }

  startTicker() {
    this.animate()
  }

  animate() {
    // Adjust position based on direction
    if (this.directionValue === "left") {
      this.position -= this.speed
      if (Math.abs(this.position) >= this.contentWidth / 2) {
        this.position = 0
      }
    } else if (this.directionValue === "right") {
      this.position += this.speed
      if (this.position >= 0) {
        this.position = -this.contentWidth / 2
      }
    }

    this.contentTarget.style.transform = `translateX(${this.position}px)`
    this.frame = requestAnimationFrame(this.animate)
  }

  stopTicker() {
    cancelAnimationFrame(this.frame)
  }

  updateWidth() {
    this.contentWidth = this.contentTarget.scrollWidth
  }

  pauseTicker() {
    cancelAnimationFrame(this.frame)
  }

  resumeTicker() {
    this.animate()
  }
}

// scroll_animation_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    delay: Number,
    threshold: { type: Number, default: 0.5 }
  }
  static classes = ["visible", "hidden"]

  connect() {
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      threshold: this.thresholdValue
    })

    this.observer.observe(this.element)
  }

  disconnect() {
    this.observer.disconnect()
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.style.transitionDelay = `${this.delayValue || 0}s`
        // Add dynamic visible classes and remove hidden classes
        entry.target.classList.add(...this.visibleClasses)
        entry.target.classList.remove(...this.hiddenClasses)
        this.observer.unobserve(entry.target) // Unobserve after animation triggers
      }
    })
  }
}
